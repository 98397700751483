import React, { useCallback, useEffect, useRef, useState } from 'react'
import mainLogoEn from "../../assets/images/logo/main-logo-l.svg"
import mainLogoAm from "../../assets/images/logo/main-logo-am-l.svg"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { LANGUAGE_KEY } from '../../constants/mainKeys'
import { setLanguage } from '../../store/actions/language'
import { setIsShowLoginModal } from './../../store/actions/main'
import Login from "./../modals/login/Login"
import TranslationService from './../../services/TranslationService'
import ReactCountryFlag from "react-country-flag"
import useScroll from '../hooks/useScroll'

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb && cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = () => {

  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { language, languages } = useSelector(state => state.language);
  const { isShowLoginModal } = useSelector(state => state.main);
  const { translations } = useSelector(state => state.translation);
  const [isShowLanguagesDropdown, setIsShowLanguagesDropdown] = useState(false);
  const [isShowMobileSizeMenu, setIsShowMobileSizeMenu] = useState(false);
  const [translationService, setTranslationService] = useState(null);
  const isScrolled = useScroll(150);

  useOutsideAlerter(wrapperRef, setIsShowLanguagesDropdown);

  const changeLanguage = isocode => {
    setIsShowMobileSizeMenu(false);
    if (!isocode || isocode === language) { return false; }
    localStorage.setItem(LANGUAGE_KEY, isocode);
    setLanguageHandler(isocode);
    const oldIsoCode = location.pathname.split("/")[1];
    setIsShowLanguagesDropdown(false)
    const newPath = location.pathname.toString().replace(oldIsoCode, `${isocode}`);
    setTimeout(() => {
      navigate(newPath);
    });
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const setLanguageHandler = useCallback(language => {
    dispatch(setLanguage(language));
  }, []);

  return translationService ?
    <React.Fragment>

      <header className={`header ${isScrolled ? "fixed-header shadow" : ""}`}>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link className="navbar-brand" to={`/${language}`} onClick={scrollToTop}>
              <img src={language === "am" ? mainLogoAm : mainLogoEn} alt="Logo" className={`d-inline-block align-text-top ${isScrolled ? "mb-1" : ""}`} />
            </Link>
            <Link
              to={`#`}
              className="ardy--w-fit-content d-lg-none link--btn primary--btn header--btn ms-auto me-4"
              onClick={() => dispatch(setIsShowLoginModal(true))}
            >
              {translationService.translate("TR_GET_STARTED")}
            </Link>
            {/*  */}
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setIsShowMobileSizeMenu(!isShowMobileSizeMenu)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse justify-content-end ${isShowMobileSizeMenu ? "show" : ""}`} id="navbarNavDropdown">
              <ul className="navbar-nav">
                {/* comment-uncomment */}
                {/* <li className="nav-item">
                  <Link
                    to={`/${language}/specialization`}
                    className={`nav-link ${location.pathname.includes("/specialization") ? "active" : ""} `}
                    aria-current="page"
                    onClick={() => setIsShowMobileSizeMenu(false)}
                  >
                    {translationService.translate("TR_SPECIALIZATION")}
                  </Link>
                </li> */}
                {/* comment-uncomment */}
                <li className="nav-item">
                  <Link
                    to={`/${language}/courses`}
                    className={`nav-link ${location.pathname.includes("/course") ? "active" : ""} `}
                    onClick={() => setIsShowMobileSizeMenu(false)}
                  >
                    {translationService.translate("TR_COURSES")}
                  </Link>
                </li>
                {/*  */}
                <li className="nav-item">
                  <Link
                    to={`/${language}/about`}
                    className={`nav-link ${location.pathname.includes("/about") ? "active" : ""} `}
                    onClick={() => setIsShowMobileSizeMenu(false)}
                  >
                    {translationService.translate("TR_ABOUT_US")}
                  </Link>
                </li>
                {/* comment-uncomment */}
                <li className="nav-item d-lg-block d-none">
                  <Link
                    to={`#`}
                    className="link--btn primary--btn header--btn"
                    onClick={() => dispatch(setIsShowLoginModal(true))}
                  >
                    {translationService.translate("TR_GET_STARTED")}
                  </Link>
                </li>
                {/*  */}
                {
                  languages && languages.length ?
                    <>
                      <li className="nav-item d-lg-block d-none">
                        <div className="ardy--current-language text-uppercase">
                          <div style={{ cursor: "pointer" }} onClick={() => setIsShowLanguagesDropdown(true)}>
                            {
                              language && languages && languages.length ?
                                languages.map((item, index) => {
                                  if (item.isoCode2 === language) {
                                    return <ReactCountryFlag
                                      key={index}
                                      countryCode={item.displayName}
                                      svg
                                      style={{
                                        width: '1.25em',
                                        height: '1.25em',
                                        cursor: "pointer"
                                      }}
                                      title={item.name}
                                    />
                                  } else {
                                    return "";
                                  }
                                })
                                : null
                            }
                          </div>
                          {
                            isShowLanguagesDropdown ?
                              <ul style={{ transform: "scale(1)" }} className="ardy--languages" ref={wrapperRef}>
                                {
                                  languages.map((item, index) => <li key={index}>
                                    <ReactCountryFlag
                                      key={index}
                                      countryCode={item.displayName}
                                      svg
                                      style={{
                                        width: '1.25em',
                                        height: '1.25em',
                                        cursor: "pointer"
                                      }}
                                      title={item.name}
                                      onClick={() => changeLanguage(item.isoCode2)}
                                    />
                                  </li>)
                                }
                              </ul>
                              : null
                          }
                        </div>
                      </li>
                      <li className="nav-item d-lg-none">
                        <div className="ardy--mobile-current-language text-uppercase">
                          <ul className="ardy--mobile-languages">
                            {
                              languages.map((item, index) => <li
                                key={index}
                                className={`cursor-pointer ${language === item.isoCode2 ? "active" : ""}`}
                                onClick={() => changeLanguage(item.isoCode2)}
                              >
                                <ReactCountryFlag
                                  countryCode={item.displayName}
                                  svg
                                  style={{
                                    width: '1.5em',
                                    height: '1.5em',
                                    cursor: "pointer"
                                  }}
                                  title={item.name}
                                />
                              </li>)
                            }
                          </ul>
                        </div>
                      </li>
                    </>
                    : null
                }
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {
        isShowLoginModal ?
          <Login />
          : null
      }
    </React.Fragment>
    : null
}

export default Header